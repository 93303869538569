<template>
  <div>
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#427D2D" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-start pa-5>
          <v-flex xs12>
            <span class="heading">Snakebite Management</span>
          </v-flex>
          <v-flex xs12 v-if="items && items.length > 0">
            <v-layout wrap justify-start pt-2>
              <!-- <v-flex xs12 sm6 md6 lg6 class="subheading">
                  <span>Rows per page</span>
                  <v-select
                    v-model="count"
                    color="#427D2D"
                    outlined
                    dense
                    hide-details
                    :items="[20, 30, 50]"
                  ></v-select>
                </v-flex> -->
              <v-flex xs12 sm6 md6 lg6 pl-md-2 pl-lg-2 pl-sm-2>
                <span class="subheading">Filter</span>
                <v-text-field
                  outlined
                  hide-details
                  dense
                  placeholder="Type To Search" class="text-des"
                  v-model="keyword"
                >
                </v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-2>
              <v-flex xs12>
                <v-card>
                  <div style="overflow-y: auto; overflow-x: auto;">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left tablehead">Created Date</th>
                        <th class="text-left tablehead">State</th>
                        <th class="text-left tablehead">Affected People</th>
                        <th class="text-left tablehead">Traditional Healing</th>
                        <th class="text-left tablehead">Notes</th>
                        <th class="text-left tablehead">Link</th>
                        <th class="text-left tablehead">Edit Details</th>
                        <th class="text-left tablehead">View Details</th>
                        <th class="text-left tablehead">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in items" :key="index">
                        <td style="white-space: nowrap;" class="tableitems">{{ formatDate(item.createddate) }}</td>
                        <td style="white-space: nowrap;" class="tableitems">{{ item.state }}</td>
                        <td style="white-space: nowrap;" class="tableitems">{{ item.affectedpeople }}</td>
                        <td style="white-space: nowrap;" class="tableitems">{{ item.tradition }}</td>
                        <td style="white-space: nowrap;" class="tableitems">{{ item.note }}</td>
                        <td style="white-space: nowrap;" class="tableitems">{{ item.linktonews }}</td>
                        <!-- Edit Details -->
                        <td style="white-space: nowrap;" class="tableitems">{{ item.status }}</td>
                        <!-- View Details -->
                        <td style="white-space: nowrap;" class="tableitems">
                          <!-- <v-btn
                            color="primary"
                            depressed
                            small
                            @click="openviewdetails(item)"
                            ><span>View Details</span></v-btn
                          > -->

                          <v-icon small color="success" class="ml-5" @click="openviewdetails(item)">mdi-eye</v-icon>

                          
                        </td>
                        <!-- Delete -->
                        <td style="white-space: nowrap;" class="tableitems">
                          <!-- <v-btn
                            color="error"
                            depressed
                            small
                            @click="opendeleteDialog(item)"
                            ><span>Delete</span></v-btn
                          > -->
                          <v-icon small color="error" class="ml-5" @click="opendeleteDialog(item)">mdi-delete</v-icon>
                        
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                </div>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 pb-4 v-if="items && items.length > 0">
        <v-layout wrap justify-center>
          <v-flex xs10>
            <v-pagination
              v-model="currentPage"
              :length="Pagelength"
              circle
              color="#427D2D"
              small
            ></v-pagination>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 v-else>
        <v-layout
          wrap
          justify-center
          align-center
         
        >
          <v-flex xs12 class="text-center">
            <span class="nodata">No Data Found !</span>
          </v-flex>
        </v-layout>
      </v-flex>

    </v-layout>
    <v-dialog v-model="deleteDialog" max-width="500">
      <v-card>
        <v-card-title class="heading">Confirmation</v-card-title>
        <v-card-text>Are you sure you want to delete?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            tile
            outlined
            color="#FF1313"
            light
            :ripple="false"
            depressed
            @click="deleteDialog = false"
            class="itemValue"
          >
            Cancel
          </v-btn>
          <v-btn
            tile
            :color="appColor"
            light
            :ripple="false"
            depressed
            @click="deleteoperation()"
            class="itemValue"
          >
            <span style="color: #fff">Submit</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="rejectDialog" max-width="500">
      <v-card>
        <v-card-title class="heading">Confirmation</v-card-title>
        <v-card-text>Are you sure you want to reject?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            tile
            outlined
            color="#FF1313"
            light
            :ripple="false"
            depressed
            @click="rejectDialog = false"
            class="itemValue"
          >
            Cancel
          </v-btn>
          <v-btn
            tile
            :color="appColor"
            light
            :ripple="false"
            depressed
            @click="rejectoperation()"
            class="itemValue"
          >
            <span style="color: #fff">Submit</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
          <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      count: 20,
      keyword: "",
      currentPage: 1,
      Pagelength: 0,
      userlist: [],
      items: [],
      status: "",
      deleteDialog: false,
      deleteid: "",
      rejectDialog: false,
      rejectid: "",
    };
  },
  beforeMount() {
    this.getData();
  },
  watch: {
    currentPage() {
      this.getData();
    },
    count() {
      this.getData();
    },
    keyword() {
      this.getData();
      this.currentPage=1
    },
  },
  methods: {
    openviewdetails(item) {
      this.$router.push({
        path: "/identificationhelpview",
        query: {
          id: item._id,
        },
      });
    },

    opendeleteDialog(item) {
      this.deleteid = item._id;
      this.deleteDialog = true;
    },
    openrejectDialog(item) {
      this.rejectid = item._id;
      this.rejectDialog = true;
    },
    formatDate(date) {
    if (!date) return '-';
    const [year, month, day] = date.slice(0, 10).split('-');
    return `${day}-${month}-${year}`;
  },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/snakebiteinfo/all",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
        keyword:this.keyword,
        count :this.count,
        page: this.currentPage,
      },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.items = response.data.data;
              this.Pagelength = response.data.pages;
              // this.msg = response.data.msg;
              // this.showSnackBar = true;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    deleteoperation() {
      this.appLoading = true;
      axios({
        method: "DELETE",
        url: "/snakebiteinfo/delete/",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.deleteid,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = "Deleted Successfully.";
              this.showSnackBar = true;
              this.appLoading = false;
              this.deleteDialog = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
            this.deleteDialog = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    rejectoperation() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/operation/removedeleterequest",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.rejectid,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = "Rejected Successfully.";
              this.showSnackBar = true;
              this.appLoading = false;
              this.rejectDialog = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
            this.rejectDialog = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
          <style>
.heading {
  font-family: poppinsregular;
  font-size: 20px;
  font-weight: 900;
}
.subheading {
  font-family: poppinsregular;
  font-size: 15px;
  font-weight: 500;
}
</style>